/**
 * @module ol/renderer/webgl/tilelayershader/Locations
 */
// This file is automatically generated, do not edit
// Run `make shaders` to generate, and commit the result.

import {DEBUG as DEBUG_WEBGL} from '../../../webgl.js';

class Locations {

  /**
   * @param {WebGLRenderingContext} gl GL.
   * @param {WebGLProgram} program Program.
   */
  constructor(gl, program) {

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_tileOffset = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_tileOffset' : 'd');

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_texture = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_texture' : 'e');

    /**
     * @type {number}
     */
    this.a_position = gl.getAttribLocation(
      program, DEBUG_WEBGL ? 'a_position' : 'b');

    /**
     * @type {number}
     */
    this.a_texCoord = gl.getAttribLocation(
      program, DEBUG_WEBGL ? 'a_texCoord' : 'c');

  }

}

export default Locations;
