/**
 * @module ol/render/webgl/circlereplay/defaultshader/Locations
 */
// This file is automatically generated, do not edit
// Run `make shaders` to generate, and commit the result.

import {DEBUG as DEBUG_WEBGL} from '../../../../webgl.js';

class Locations {

  /**
   * @param {WebGLRenderingContext} gl GL.
   * @param {WebGLProgram} program Program.
   */
  constructor(gl, program) {

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_projectionMatrix = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_projectionMatrix' : 'h');

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_offsetScaleMatrix = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_offsetScaleMatrix' : 'i');

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_offsetRotateMatrix = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_offsetRotateMatrix' : 'j');

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_lineWidth = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_lineWidth' : 'k');

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_pixelRatio = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_pixelRatio' : 'l');

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_opacity = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_opacity' : 'm');

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_fillColor = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_fillColor' : 'n');

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_strokeColor = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_strokeColor' : 'o');

    /**
     * @type {WebGLUniformLocation}
     */
    this.u_size = gl.getUniformLocation(
      program, DEBUG_WEBGL ? 'u_size' : 'p');

    /**
     * @type {number}
     */
    this.a_position = gl.getAttribLocation(
      program, DEBUG_WEBGL ? 'a_position' : 'e');

    /**
     * @type {number}
     */
    this.a_instruction = gl.getAttribLocation(
      program, DEBUG_WEBGL ? 'a_instruction' : 'f');

    /**
     * @type {number}
     */
    this.a_radius = gl.getAttribLocation(
      program, DEBUG_WEBGL ? 'a_radius' : 'g');

  }

}

export default Locations;
