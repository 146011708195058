/**
 * @module ol/ViewProperty
 */

/**
 * @enum {string}
 */
export default {
  CENTER: 'center',
  RESOLUTION: 'resolution',
  ROTATION: 'rotation'
};
